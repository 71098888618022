import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import { Link } from 'gatsby'
import privacyPolicy from '../assets/images/privacy-policy.jpg'

const PrivacyPolicy = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Privacy Policy" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Privacy Policy" 
            />
            <section className="privacy-policy-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="privacy-policy-content">
                                <p><i>This Privacy Policy was last updated on January 1, 2020.</i></p>
                                
                                <p>The protection of your personal data is of utmost importance to us. We therefore process your data exclusively on the basis of the statutory provisions (GDPR, TCA 2003). In this privacy policy we inform you about the most important aspects of data processing on our website.</p>
                                
                                <h3>Contact us</h3>
                                <p>If you contact us via the form on our website or via e-mail the data you provide will be stored by us for six months for the purpose of processing the enquiry and for the case of follow-up questions. We will not pass on this data without your consent.</p>
                                
                                <h3>Data storage</h3>
                                <p>We would like to point out that for the purpose of simplifying the purchasing process and for later contract processing the IP data of the connection owner, as well as name, address and credit card number of the purchaser are stored by the website operator in the form of cookies. In addition, the following data is also stored by us for the purpose of contract processing: e-mail address.</p>
                                <p>The data provided by you is necessary for the fulfillment of the contract or for the execution of precontractual measures. Without this data we cannot conclude the contract with you. A data transfer to third parties does not take place, with the exception of the transfer of credit card data to the processing bank institutes/payment service providers for the purpose of debiting the purchase price, to the transport company/ shipping company commissioned with the delivery of the goods as well as to our tax advisor to fulfill our tax obligations. The data stored by us will be deleted after the purchase process has been aborted. If a contract is concluded, all data from the contractual relationship will be stored until the end of the tax retention period (7 years).</p>
                                <p>Furthermore, the data name, address, purchased goods and date of purchase will be stored until the expiration of the product liability (10 years). Data processing is based on the statutory provisions of § 96 para 3 TCA as well as Art. 6 para 1 lit a (consent) and/or lit b (necessary for contract fulfillment) of the GDPR.</p>                                
                                
                                <h3>Cookies</h3>
                                <p>Our website uses so-called cookies. These are small text files that are stored on your end device with the help of the browser. They do not cause any damage. We use cookies to make our website user-friendly. Some cookies remain stored on your end device until you delete them. They allow us to recognize your browser the next time you visit. If you do not wish this, you can set up your browser so that it informs you when cookies are set and you only allow this in certain cases. If cookies are deactivated, the functionality of our website may be restricted.</p>
                                
                                <h3>Web analytics</h3>
                                <p>Our website uses functions of the web analysis service Google Analytics, Google, USA. For this, cookies are used that enable an analysis of the website usage by its users. The information generated in this way is transferred to the provider’s server and stored there. You can prevent this by setting your browser in a way that no cookies are stored. We have concluded a corresponding contract with the provider for contract data processing.</p>
                                <p>Your IP address will be recorded but immediately pseudonymized. Thus, only a rough localization is possible. The relationship with the web analysis provider is based on an adequacy decision of the European Commission (e.g. in the case of the USA: “Privacy Shield”). Data processing is based on the statutory provisions of § 96 para. 3 TCA and Art. 6 para. 1 lit a (consent) and/or f (legitimate interest) of the GDPR. Our intention in the sense of the GDPR (legitimate interest) is the improvement of our offer and our website. Since the privacy of our users is important to us, the user data is pseudonymized.</p>
                                <p>User data is stored for 7 years.</p>
                                <h6>Your rights</h6>
                                <p>In general, you are entitled to the rights of information, correction, deletion, restriction, data transferability, revocation and objection. If you believe that the processing of your data violates the data protection law or your claims in terms of data protection law have otherwise been violated in any way, you can file a complaint with the supervisory authority. In Austria this is the data protection authority.</p>
                                
                                <p>
                                    visionar GmbH<br/>
                                    Mitterweg 16/II<br/>
                                    6020 Innsbruck<br/>
                                    Austria<br/>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    )
}

export default PrivacyPolicy;